import axios from 'axios';
import is from 'is_js';

const AdminService = {

    getHostsByStatus: (statusId, pageSize, pageNumber, searchQuery) => {
        console.log(`AdminService.getHostsByStatus()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/search/`;
        const req = {
            status: statusId,
            page_size: pageSize,
            page_number: pageNumber
        };
        if (searchQuery && is.not.empty(searchQuery)) req.search_query = searchQuery;
        return axios.post(url, req);
    },

    updateHostStatus: (hostId, statusId) => {
        console.log(`AdminService.updateHostStatus(hostId, statusId)`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/status`;
        return axios.patch(url, {
            host_id: hostId,
            status: statusId
        });
    },

    getHostById: (hostId) => {
        console.log(`AdminService.getHostById(${hostId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}`;
        return axios.get(url);
    },

    getHostsPaymentsByStatus: (statusId) => {
        console.log(`AdminService.getHostsPaymentsByStatus(statusId)`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/payment/${statusId}`;
        return axios.get(url);
    },

    getSpacesByStatus: ({
        host_status,
        space_host_active,
        space_admin_approval,
        page_size,
        page_number,
        space_id,
        space_type_id,
        is_admin_reviewed,
        host_name_or_email
    }) => {
        console.log(`AdminService.getSpacesByStatus()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/search`;
        const req = {
            host_status,
            space_host_active,
            space_admin_approval,
            page_size,
            page_number,
            space_id,
            space_type_id,
            is_admin_reviewed,
            host_name_or_email
        };
        return axios.post(url, req);
    },

    getSpacesByHostId: (hostId) => {
        console.log(`AdminService.getSpacesByHostId(${hostId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/space`;
        return axios.get(url);
    },

    updateSpaceStatus: (spaceId, status) => {
        console.log(`AdminService.updateSpaceStatus(${spaceId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/status`;
        return axios.patch(url, {
            space_id: spaceId,
            status: status
        });
    },

    getBookings: (status, dateFrom, dateTo) => {
        console.log(`AdminService.getBookings(${status}, ${dateFrom} ${dateTo})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/search`;
        return axios.post(url, { status, dateFrom, dateTo });
    },

    getPayments: (adminStatusArray) => {
        console.log(`AdminService.getPayments(${adminStatusArray})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/payment/search`;
        return axios.post(url, { adminStatus: adminStatusArray });
    },

    getPaymentsByHost: (adminStatusArray, hostId, currencyName) => {
        console.log(`AdminService.getPayments(${adminStatusArray})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/payment/host/${hostId}`;
        return axios.post(url, { adminStatus: adminStatusArray, hostId, currencyName });
    },

    generateHostPaymentsExcel: (adminStatusArray, hostId) => {
        console.log(`AdminService.getPayments(${adminStatusArray})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/payment/host/${hostId}/excel`;
        return axios.post(url, { adminStatus: adminStatusArray, hostId });
    },

    getPaymentsSummaryExcel: (hostId) => {
        console.log(`AdminService.getPaymentsSummaryExcel(${hostId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/payment/excel-summary`;
        return axios.get(url);
    },

    getUsersByStatus: (statusId, pageSize, pageNumber, searchQuery) => {
        console.log(`AdminService.getUsersByStatus(${statusId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/user/search`;
        const req = {
            status: statusId,
            page_size: pageSize,
            page_number: pageNumber
        };
        if (searchQuery && is.not.empty(searchQuery)) req.search_query = searchQuery;
        return axios.post(url, req);
    },

    updateUserStatus: (userId, statusId) => {
        console.log(`AdminService.updateUserStatus(${userId}, ${statusId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/user/status`;
        return axios.patch(url, {
            user_id: userId,
            status: statusId
        });
    },

    getDashboardData: () => {
        console.log(`AdminService.getDashboardData()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/dashboard`;
        return axios.get(url);
    },

    pay: (payment_ids, payment_method_id, bank_account_id) => {
        console.log(`AdminService.pay(${payment_ids}, ${payment_method_id})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/payment`;
        return axios.post(url, { payment_ids, payment_method_id, bank_account_id });
    },

    getPromoCodes: () => {
        console.log(`AdminService.getPromoCodes()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/promocode`;
        return axios.get(url);
    },

    getPromoCodeBookings: (promocodeId) => {
        console.log(`AdminService.getPromoCodeBookings(${promocodeId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/promocode/${promocodeId}/booking`;
        return axios.get(url);
    },

    getEmployeesByCompanyCountry: (companyId, companyCountryId) => {
        console.log(`AdminService.getEmployeesByCompanyCountry(${companyId}, ${companyCountryId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/country/${companyCountryId}/employees`;
        return axios.get(url);
    },

    updatePromoCode: (codeId, data) => {
        console.log(`AdminService.updatePromoCode(${codeId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/promocode/${codeId}`;
        return axios.patch(url, data);
    },

    createPromoCode: (data) => {
        console.log(`AdminService.createPromoCode`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/promocode`;
        return axios.post(url, data);
    },

    getBookingsByStatus: (hostStatusIdList, userStatusIdList, pageSize, pageNumber, searchQuery) => {
        console.log(`AdminService.getBookingsByStatus(${hostStatusIdList}, ${userStatusIdList}, ${pageSize}, ${pageNumber})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/search`;
        const req = {
            page_size: pageSize,
            page_number: pageNumber
        };
        if (hostStatusIdList) req.host_status = hostStatusIdList;
        if (userStatusIdList) req.user_status = userStatusIdList;
        if (searchQuery && is.not.empty(searchQuery)) req.booking_id = searchQuery;
        return axios.post(url, req);
    },

    getTransactionsByStatus: (pageSize, pageNumber, searchQuery, type) => {
        console.log(`AdminService.getBookingsByStatus( ${pageSize}, ${pageNumber}, ${searchQuery}, ${type})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/transaction/search`;
        const req = {
            page_size: pageSize,
            page_number: pageNumber,
            search_query: searchQuery,
            type
        };
        return axios.post(url, req);
    },

    getCompaniesByStatus: (statusId, company_type, pageSize, pageNumber, companyName) => {
        console.log(`AdminService.getCompaniesByStatus(${statusId, company_type})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/search`;
        const req = {
            status: statusId,
            page_size: pageSize,
            page_number: pageNumber,
            company_type,
            only_active_employees: true,
            include_features: true,
        };
        if (companyName && is.not.empty(companyName)) req.company_name = companyName;
        return axios.post(url, req);
    },

    updateCompanyStatus: (companyId, statusId) => {
        console.log(`AdminService.updateCompanyStatus(${companyId}, ${statusId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/status`;
        return axios.patch(url, {
            company_id: companyId,
            status: statusId
        });
    },

    getSpaceById: (spaceId) => {
        console.log(`AdminService.getSpaceById(${spaceId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/${spaceId}`;
        return axios.get(url);
    },

    updateSpace: (space) => {
        console.log(`AdminService.updateSpace(${space.id})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/${space.id}`;
        return axios.patch(url, space);
    },

    setSpaceAvailability: (spaceId, availabilities) => {
        console.log(`AdminService.setSpaceAvailability(${spaceId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/${spaceId}/availability`;
        return axios.patch(url, availabilities);
    },

    getSpaceAvailabilities: (spaceId) => {
        console.log(`AdminService.getSpaceAvailabilities(${spaceId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/space/${spaceId}/availability`;
        return axios.get(url);
    },

    getCompanyPayments: () => {
        console.log('adminService.getCompanyPayments');
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company-payment/search`;
        return axios.post(url, {});
    },

    markBudgetAsPaid: (budget_id) => {
        console.log(`AdminService.markBudgetAsPaid(${budget_id})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company-country-budget`;
        return axios.patch(url, {
            budget_ids: [budget_id],
            paid: true
        });
    },

    cancelBookingAsUser: (bookingId, body) => {
        console.log(`AdminService.cancelBookingAsUser(${bookingId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/${bookingId}/user/cancel`;
        return axios.patch(url, body);
    },

    cancelBookingAsHost: (bookingId) => {
        console.log(`AdminService.cancelBookingAsHost(${bookingId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/host-status`;
        return axios.patch(url, {
            booking_id: bookingId,
            host_status: 3 // cancel status
        });
    },

    acceptBookingAsHost: (bookingId) => {
        console.log(`AdminService.acceptBookingAsHost(${bookingId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/host-status`;
        return axios.patch(url, {
            booking_id: bookingId,
            host_status: 2 // approved status
        });
    },
    getRevenues: (currencyCode) => {
        console.log(`AdminService.getRevenues`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/revenue?currencyCode=${currencyCode}`;
        return axios.get(url);
    },

    setBank: (data) => {
        console.log(`AdminService.setBank`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/create/bank-account`;
        return axios.post(url, data);
    },

    getAllBanks: (hostId, currency) => {
        console.log(`AdminService.getAllBanks(${hostId}, ${currency})`);
        let url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/bank-account`;
        if (currency) url += `?currency=${currency}`;
        return axios.get(url);
    },

    getBankById: (bankAccountId) => {
        console.log(`AdminService.getBanksById(${bankAccountId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/bank-account/${bankAccountId}`;
        return axios.get(url);
    },

    editBank: (hostId, data) => {
        console.log(`AdminService.editBank(${hostId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/update/bank-account/`;
        return axios.patch(url, { ...data, hostId });
    },

    removeBank: (hostId, bankAccountId) => {
        console.log(`AdminService.removeBank()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host${hostId}/bankAccount/${bankAccountId}`;
        return axios.delete(url);
    },

    getHistoricalAndYtdGmv: (countryCode = '', currencyCode = 'USD') => {
        console.log(`AdminService.getHistoricalAndYtdGmv()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/historical-and-ytd-gmv?currencyCode=${currencyCode}&countryCode=${countryCode}`;
        return axios.get(url);
    },

    getGMVByCountry: (countryCode = '', saleLeadId, currencyCode = 'USD') => {
        console.log(`AdminService.getGMVByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/revenue-by-country?currencyCode=${currencyCode}&countryCode=${countryCode}&saleLeadId=${saleLeadId}`;
        return axios.get(url);
    },

    getGMVByCompany: (month = '', countryCode = '', currencyCode = 'USD') => {
        console.log(`AdminService.getGMVByCompany()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/revenue-by-company?currencyCode=${currencyCode}&month=${month}&countryCode=${countryCode}`;
        return axios.get(url);
    },

    getMonthlyBookingsByCountry: (countryCode = '') => {
        console.log(`AdminService.getMonthlyBookingsByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/monthly-bookings-by-country?countryCode=${countryCode}`;
        return axios.get(url);
    },

    getCancelledBookings: (countryCode = '', status) => {
        console.log(`AdminService.getCancelledBookings()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/monthly-cancelled-bookings?countryCode=${countryCode}&userStatus=${status.userStatus}&hostStatus=${status.hostStatus}`;
        return axios.get(url);
    },

    getChurnRate: (countryCode = '') => {
        console.log(`AdminService.getChurnRate()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/churn-rate?countryCode=${countryCode}`;
        return axios.get(url);
    },

    getMonthlyBookingsBySpaceType: (countryCode = '', month = '') => {
        console.log(`AdminService.getMonthlyBookingsBySpaceType()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/monthly-bookings-by-space-type?countryCode=${countryCode}&month=${month}`;
        return axios.get(url);
    },

    getCompaniesByCountry: (countryCode = '', companyName = '', month = '') => {
        console.log(`AdminService.getCompaniesByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/companies-by-country?countryCode=${countryCode}&companyName=${companyName}&month=${month}`;
        return axios.get(url);
    },

    getCompaniesCountByCountry: (countryCode = '', month = '') => {
        console.log(`AdminService.getCompaniesCountByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/companies-count-by-country?countryCode=${countryCode}&month=${month}`;
        return axios.get(url);
    },

    getCompaniesPercentByCountry: () => {
        console.log(`AdminService.getCompaniesPercentByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/companies-percent-by-country`;
        return axios.get(url);
    },

    getCompaniesEmployeesPercentByCountry: (countryCode, month = '') => {
        console.log(`AdminService.getCompaniesEmployeesPercentByCountry()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/companies-employee-percent-by-country?countryCode=${countryCode}&month=${month}`;
        return axios.get(url);
    },

    getBudgetVsCredits: (countryCode = '', companyId = '') => {
        console.log(`AdminService.getBudgetVsCredits()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/budget-vs-credits?countryCode=${countryCode}&companyId=${companyId}`;
        return axios.get(url);
    },

    getMonthlyNewUsers: (countryCode = '') => {
        console.log(`AdminService.getMonthlyNewUsers()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/monthly-new-users?countryCode=${countryCode}`;
        return axios.get(url);
    },

    getCompanyAdmins: (companyId) => {
        console.log(`AdminService.getCompanyAdmins(${companyId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/admin`;
        return axios.get(url);
    },

    deleteAdmin: (companyId, adminId) => {
        console.log(`AdminService.deleteAdmin(${companyId}, ${adminId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/admin/${adminId}`;
        return axios.delete(url);
    },

    /**
     * @deprecated
     * Use createCompanyAdmins() instead
     */
    createNewAdmin: (emails, companyId) => {
        console.log(`AdminService.createNewAdmin(${emails.join()}, ${companyId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/admin`;
        return axios.post(url, { companyId, emails });
    },

    createCompanyAdmins: (companyId, body) => {
        console.log(`AdminService.createCompanyAdmins(${companyId}, ${body})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/admin`;
        return axios.post(url, body);
    },

    getFullCompanyById: (companyId) => {
        console.log(`AdminService.getFullCompanyById(${companyId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/detail`;
        return axios.get(url);
    },

    getLinkToSignContractByAdmin: (idBooking) => {
        console.log(`AdminService.getLinkToSignContract(${idBooking})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/booking/${idBooking}/sign-url`;
        return axios.get(url);
    },

    getVendors: () => {
        console.log(`AdminService.getVendors()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/sales-leads`;
        return axios.get(url);
    },

    updateCompanySalesLeads: (companyId, users) => {
        console.log(`AdminService.updateCompanySalesLeads()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/sales_lead`;
        return axios.patch(url, { user_id: users });
    },

    updateCompanyAcumCredit: (idCompany, isAccumulate) => {
        console.log(`CompanyService.updateCompanyAcumCredit(${idCompany}, ${isAccumulate})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${idCompany}/accumulate_credits/${isAccumulate}`;

        return axios.patch(url);
    },
    updateAllowUnlimited: (data) => {
        console.log(`AdminService.updateAllowUnlimited(cid${data.idCompany},ccid${data.idCompanyCountry})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${data.idCompany}/country/${data.idCompanyCountry}`;
        return axios.patch(url, { is_unlimited_allowed: data.is_unlimited_allowed });

    },
    getAvailableMonths: (idCompany) => {
        console.log(`CompanyService.getAvailableMonths(${idCompany})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${idCompany}/summary_months`;
        return axios.get(url);
    },
    getSummaryPdf: (idCompany, selectedToDate) => {
        console.log(`CompanyService.getSummaryPdf('${idCompany}'`);
        let url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${idCompany}/employee_summary`;
        return axios.post(url, { date: selectedToDate },
            {
                responseType: "blob",
            });
    },
    getHostPayments: (hostId) => {
        console.log(`AdminService.getHostPayments()`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/payment/summary`;
        return axios.get(url);
    },
    getCompanyPointsBudgets: (companyId) => {
        console.log(`AdminService.getCompanyPointsBudgets(${companyId})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/points-budget`;
        return axios.get(url);
    },
    updatePointsBudget: (company_id, points, mode = 'OVERRIDE', duration_in_months, sold_value_in_usd) => {
        console.log(`AdminService.updatePointsBudget(${company_id, points, mode, duration_in_months, sold_value_in_usd})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${company_id}/points-budget`;
        return axios.post(url, {
            company_id,
            points,
            duration_in_months,
            sold_value_in_usd,
            mode
        });
    },

    setPointsBudgetPaid: (companyId, pointsBudgetId, isPaid) => {
        console.log(`AdminService.setPointsBudgetPaid(${companyId, pointsBudgetId, isPaid})`);
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/company/${companyId}/points-budget/${pointsBudgetId}/paid/${isPaid}`;
        return axios.post(url);
    },

    getRequestedSpacePriceChangeByStatus: (status) => {
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/requested-space-price-change/status/${status}`;
        return axios.get(url);
    },

    toggleRequestedSpacePriceChange: (body) => {
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/requested-space-price-change`;
        return axios.patch(url, body);
    },

    getRequestedSpacePriceChangeBySpaceId: (spaceId) => {
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/requested-space-price-change/space/${spaceId}`;
        return axios.get(url);
    },

    postSpaceImage: (hostId, spaceId, base64, onUploadProgress, cancelToken) => {
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/space/${spaceId}/image`;
        return axios.post(url, {
            base64,
        },
            { onUploadProgress, cancelToken });
    },

    deleteSpacePhoto: (hostId, spaceId, uuid) => {
        const url = `${process.env.REACT_APP_API_ADMIN_URL}/admin/host/${hostId}/space/${spaceId}/image/${uuid}`;
        return axios.delete(url);
    },
};
export default AdminService;
