import React, { useState, useContext, useEffect, useRef } from "react";
import { Box, Button, Select, MenuItem, FormHelperText, FormControlLabel, Checkbox, InputBase, Tooltip, Badge, CircularProgress } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import Calendar from 'react-calendar-multiday'
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { useAuth0 } from "@auth0/auth0-react";
import momentTimezone from 'moment-timezone';
import EditionButtons from "./EditionButtons";
import is from "is_js";
import AdminService from "../../services/AdminService";
import Picker from 'react-month-picker';
import "react-month-picker/css/month-picker.css";

const EARLIER_CHECKIN_ALLOWED = '00:00'; // h:mm
const LATER_CHECKOUT_ALLOWED = '23:30'; // h:mm
const PRIVATE_OFFICE = 'private-office';

const normalizeTime = (t) => t?.toLocaleString('en-US', { minimumIntegerDigits: 2 }) || '';

const completeTimesOptions = Array(24).fill(null).map((_, i) => i).map(h => [`${normalizeTime(h)}:00`, `${normalizeTime(h)}:30`]).flat();

const allowedTimeOptions = completeTimesOptions.slice(
    completeTimesOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
    completeTimesOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 2
);



const Availability = (props) => {
    const isNarrowWidth = useMediaQuery({ query: '(max-width: 800px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { context } = props;
    const [availableDates, setAvailableDates] = useState([]);
    const [originalAvailableDates, setOriginalAvailableDates] = useState([]);
    const { styles: contextStyles, stepUtils, space } = useContext(context);
    const [checkinOptions, setCheckinOptions] = useState(allowedTimeOptions);
    const [checkoutOptions, setCheckoutOptions] = useState(allowedTimeOptions);
    const [checkin, setCheckin] = useState(space.check_in_time ? moment(space.check_in_time, ["H:mm"]).format("HH:mm") : EARLIER_CHECKIN_ALLOWED);
    const [checkout, setCheckout] = useState(space.check_out_time ? moment(space.check_out_time, ["H:mm"]).format("HH:mm") : LATER_CHECKOUT_ALLOWED);
    const [is24open, setIs24open] = useState(space.open_all_day);
    const { user } = useAuth0();
    const [isUnlock, setIsUnlock] = useState(true);
    const [errors, setErrors] = useState({});
    const [daysWithBookings, setDaysWithBookings] = useState([]);
    const [loading, setLoading] = useState(false);

    const [monthRange, setMonthRange] = useState([{}]);

    const isPrivateOffice = space?.type?.name === PRIVATE_OFFICE;
    let quantity = isPrivateOffice ? monthRange.length : availableDates.length;

    const styles = {
        onboardingTitle: {
            fontFamily: 'PoppinsBold',
            fontWeight: 'bold',
            color: '#383839',
            fontSize: '20px',
            paddingTop: '20px'
        },
        formControl: {
            minWidth: isNarrowWidth ? 120 : 300,
            marginBottom: '20px',
            width: isNarrowWidth ? '100%' : '45%'
        },
        calendarBoxes: {
            width: isNarrowWidth ? '100%' : '45%',
        },
        ...contextStyles
    }

    const getAvailabilities = () => {
        if (space.id)
            AdminService.getSpaceAvailabilities(space?.id).then(resp => {
                const availabs = resp.data;
                setDaysWithBookings(availabs.filter(a => a.dailyUsedSpaces > 0).map(a => a.date));
                let spaceAvailabilities = availabs.map(a => moment(a.date)).filter(a => {
                    const isTodayOrAfter = (a.isAfter(moment()) || a.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'));
                    return isTodayOrAfter;
                });
                setAvailableDates(spaceAvailabilities);
                setOriginalAvailableDates(spaceAvailabilities);
                setLoading(false);
            });
    };

    useEffect(() => {
        getAvailabilities();
        // eslint-disable-next-line 
    }, [user]);

    useEffect(() => {
        setCheckoutOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(checkin) + 1,
            allowedTimeOptions.indexOf(LATER_CHECKOUT_ALLOWED) + 1
        ));
    }, [checkin]);

    useEffect(() => {
        setCheckinOptions(allowedTimeOptions.slice(
            allowedTimeOptions.indexOf(EARLIER_CHECKIN_ALLOWED),
            allowedTimeOptions.indexOf(checkout)
        ));
    }, [checkout]);

    useEffect(() => {
        if (is24open) {
            setCheckin('');
            setCheckout('');
        } else {
            setCheckin(space.check_in_time ? moment(space.check_in_time, ["H:mm"]).format("HH:mm") : EARLIER_CHECKIN_ALLOWED);
            setCheckout(checkoutOptions.find(ato => ato === moment(space.check_out_time, ["H:mm"]).format("HH:mm")) || LATER_CHECKOUT_ALLOWED);
        }
        // eslint-disable-next-line
    }, [is24open]);

    const calcAddedDates = () => {
        const availabs = availableDates.map(t => moment.utc(t.format().split('T')[0]));

        let nad = [];
        availabs.forEach(ad => {
            if (originalAvailableDates.filter(oad => oad.format('DD-MM-YYYY') === ad.utc().format('DD-MM-YYYY')).length === 0) nad.push(ad);
        });

        //remove duplicates
        nad = nad.filter((v, i) => {
            return nad.findIndex(candidate => v.isSame(candidate)) === i;
        });
        return nad;
    };

    const calcRemovedDates = () => {
        const availabs = availableDates.map(t => moment.utc(t.format().split('T')[0]));

        let rad = [];
        originalAvailableDates.forEach(oad => {
            if (availabs.filter(ad => ad.format('DD-MM-YYYY') === oad.format('DD-MM-YYYY')).length === 0) rad.push(moment.utc(oad.format().split('T')[0]));
        });

        //remove duplicates
        rad = rad.filter((v, i) => {
            return rad.findIndex(candidate => v.isSame(candidate)) === i;
        });
        return rad;
    };

    const getAvailabilityData = () => {
        let added = calcAddedDates();
        let removed = calcRemovedDates();
        return {
            added,
            removed,
        }
    };

    const validateFormData = () => {
        let errorsAcum = {};
        // if (is.empty(availableDates)) errorsAcum.availableDates = 'Debes tener al menos un dia disponible.';
        if (!is24open && calcHourDiff(checkin, checkout) < 5) errorsAcum.time = 'Debes tener al menos 5hs entre el horario de apertura y de cierre.';

        let isValid = is.empty(errorsAcum);
        setErrors(errorsAcum);
        return isValid;
    };

    const getCheckinAndCheckoutData = () => ({
        checkInTime: checkin,
        checkOutTime: checkout,
        open_all_day: is24open
    });

    const dateSelection = (e) => {
        setAvailableDates(e.selected.map(e => moment(e)));
    };

    const saveFn = async () => {
        if (validateFormData()) {
            await AdminService.setSpaceAvailability(space.id, getAvailabilityData());
            await stepUtils.updateSpace(getCheckinAndCheckoutData());
            getAvailabilities();
        }
    }

    

    return <Box style={styles.stepsView}>
        <Box className="onboardingForm">
            <div style={styles.onboardingTitle}>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span>Disponibilidad</span>
                    {loading && <CircularProgress size={30} style={{ marginLeft: 5 }} />}
                </Box>
                <Box style={{ minHeight: '20px' }}></Box>
            </div>

            <Box style={{ display: 'flex', marginBottom: '20px', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Marcá en el calendario las fechas que disponés para ofrecer tu espacio</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, marginBottom: '20px', fontSize: '16px' }}>Podés editarlas en todo momento</div>
                </Box>
                <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>


                    <FormControl style={{ ...styles.calendarBoxes, marginBottom: isMobile ? '20px' : 0, marginTop: '38px' }} error={!!errors.availableDates}>

                        {isPrivateOffice ?
                            <>
                                <MonthAvailabilityCalendar availableDates={availableDates} setAvailableDates={setAvailableDates} monthRange={monthRange} setMonthRange={setMonthRange} daysWithBookings={daysWithBookings} />
                                <LockOrUnlockYearButton availableDates={availableDates} setAvailableDates={setAvailableDates} daysWithBookings={daysWithBookings} />
                            </>
                            :
                            <>
                                <Calendar selected={availableDates} className="coco" isMultiple={true} DayComponent={<PositionDay daysWithBookings={daysWithBookings} />} onChange={dateSelection} />
                                <LockOrUnlockMonthButton availableDates={availableDates} setAvailableDates={setAvailableDates} daysWithBookings={daysWithBookings} />
                            </>
                        }

                        <FormHelperText>{errors.availableDates}</FormHelperText>
                    </FormControl>

                    <div style={{ ...styles.calendarBoxes, marginTop: isMobile ? 0 : '45px', marginBottom: isMobile ? 0 : '36px', background: 'rgba(216, 216, 216, 0.128141)', border: '1px solid #D8D8D8', borderRadius: '3px', padding: isMobile ? '5px' : '25px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '300px', textAlign: 'left' }}>
                        <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Tu calendario está configurado para estar completamente bloqueado de manera predeterminada</div>
                        <div style={{ color: '#818181', fontSize: isMobile ? '14px' : '18px' }}>Cero días disponibles para reservar</div>
                        <div style={{ color: '#00D1BF', fontSize: isMobile ? '14px' : '18px' }}>Cambiar la configuración de disponibilidad</div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', backgroundColor: 'white', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                <div style={{ color: '#818181', paddingLeft: '10px' }}>Disponible</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div className="diagonal-container" style={{ width: isMobile ? '25px' : '40px', height: isMobile ? '25px' : '40px', border: '0.7px solid #D8D8D8', borderRadius: '2.1px' }}></div>
                                <div style={{ color: '#818181', paddingLeft: '10px' }}>Bloqueada</div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'space-around', justifyContent: 'space-around', flexDirection: 'column', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', justifyContent: 'center', textAlign: 'left', padding: '20px 0' }}>
                    <div style={{ color: '#383839', marginBottom: '10px', fontSize: '18px' }}>Definí un horario de entrada y salida de tu espacio</div>
                    <div style={{ color: '#6D7278', opacity: 0.7, fontSize: '16px' }}>Podés editarlas en todo momento</div>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
                    <FormControlLabel control={<>
                        <Checkbox value={is24open} onChange={e => setIs24open(e.target.checked)} checked={is24open} />
                        <Box style={{ marginRight: 5 }}>Abierto 24 horas</Box>
                    </>} />
                </Box>
                {!is24open && <Box>
                    <FormControl style={{ ...styles.formControl, order: 4, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', flexWrap: 'wrap' }} error={!!errors.time}>
                        <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 10px 0 0', marginBottom: isMobile ? '25px' : '0', }}>
                            <Select id="checkInTime" value={checkin} onChange={e => setCheckin(e.target.value)} input={<InputBase />}>
                                {checkinOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{moment(ht, ["HH:mm"]).format("hh:mm A")}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: isMobile ? '100%' : '45%', margin: isMobile ? '0' : '0 0 0 10px', height: '48px' }}>
                            <Select id="checkOutTime" value={checkout} onChange={e => setCheckout(e.target.value)} input={<InputBase />}>
                                {checkoutOptions.map(ht => <MenuItem key={`ht_${ht}`} value={ht}>{moment(ht, ["HH:mm"]).format("hh:mm A")}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormHelperText>{errors.time}</FormHelperText>
                    </FormControl>
                </Box>}
            </Box>
        </Box>
        {is.not.empty(errors) && <FormHelperText error={true}>Valide los datos ingresados</FormHelperText>}
        <EditionButtons stepUtils={stepUtils} saveFn={saveFn} />
    </Box>;
};

export default Availability;




const MonthAvailabilityCalendar = ({ availableDates, setAvailableDates, monthRange, setMonthRange, daysWithBookings }) => {
    const [reloadPickerCounter, setReloadPickerCounter] = useState(0);
    const pickMulti = useRef();

    const currentYearDate = moment().format('YYYY');
    const currentMonth = moment().format('MM');
    const next5Years = +currentYearDate + 5;

    const handleClickMultiBox = (e) => {
        /* eslint-disable no-unused-expressions */
        pickMulti?.current?.show();
    };

    const handleMultiChange = (yearNumber, monthNumber) => {
        let isUnlock = !monthRange.some(m => is.not.empty(m) && m.year == yearNumber && m.month == monthNumber);
        if (isUnlock) {
            unlockMonth(yearNumber, monthNumber);
            setMonthRange(mv => [...mv.filter(m => is.not.empty(m)), { year: yearNumber, month: monthNumber }].sort(sortMonthRange));
        } else {
            lockMonth(yearNumber, monthNumber);
            setMonthRange(mv => [...new Set(mv.filter(v => v.year != yearNumber || v.month != monthNumber))].sort(sortMonthRange));
        }
    };


    useEffect(() => {
        /* eslint-disable no-unused-expressions */
        if (pickMulti.current) pickMulti?.current?.show();
    }, [pickMulti.current]);


    const setMonthAvailability = () => {
        let allAvailableMonths = [...new Set(availableDates.map(a => moment.utc(a).format("M-YYYY")))];

        const allDaysListByMonth = {};
        allAvailableMonths.forEach(b => {
            const [month, year] = b.split("-");
            allDaysListByMonth[b] = getMonthDays(year, month).map(a => moment.utc(a).format("YYYY-MM-DD"));
        });
        if (is.empty(allDaysListByMonth)) {
            setMonthRange([{}]);
            setReloadPickerCounter(r => r + 1);
        } else {
            Object.keys(allDaysListByMonth).forEach(ad => {
                const currentMonthAvailability = [...new Set(availableDates
                    .filter(d => moment.utc(d).format("M-YYYY") == ad)
                    .filter(d =>  moment.utc(d).isoWeekday() !== 6 &&  moment.utc(d).isoWeekday() !== 7)
                    .map(a => moment.utc(a).format("YYYY-MM-DD"))
                )];
                let currentMonthAvailabilityString = JSON.stringify(currentMonthAvailability.sort());
                let allMonthWeekdaysString = JSON.stringify(allDaysListByMonth[ad].sort());
                let haveSameDays = currentMonthAvailabilityString === allMonthWeekdaysString;

                let year = +ad.split("-")[1];
                let month = +ad.split("-")[0];

                if (haveSameDays && !monthRange.some(m => is.not.empty(m) && m.year == year && m.month == month)) {
                    setMonthRange(mv => [...mv.filter(m => is.not.empty(m)), { year: year, month: month }].sort(sortMonthRange));
                    setReloadPickerCounter(r => r + 1);
                }
            });
        }

    };

    useEffect(() => {
        setMonthAvailability();
    }, [availableDates]);

    //selecciona mes completo en calendario

    const unlockMonth = (selectedyear, selectedMonthNumber) => {
        let currentMonthDays = getMonthDays(selectedyear, selectedMonthNumber);
        setAvailableDates(ad => [...ad, ...currentMonthDays]);
    };

    const lockMonth = (selectedyear, selectedMonthNumber) => {
        let currentMonthDays = getMonthDays(selectedyear, selectedMonthNumber);
        let lockableDays = currentMonthDays.filter(d => !daysWithBookings.includes(d.format('YYYY-MM-DD')));

        // substract the current month days to the availableDates selected
        let newAvailableDates = (() => {
            let nad = [];
            availableDates.forEach(ad => {
                if (lockableDays.filter(cmd => cmd.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')).length === 0) nad.push(ad);
            });
            return nad;
        })();

        setAvailableDates(newAvailableDates);
    };


    return <div className="monthCalendarContainerSpace">
        <Picker
            age={reloadPickerCounter}
            ref={pickMulti}
            years={{ min: { year: +currentYearDate, month: +currentMonth }, max: { year: +next5Years, month: 12 } }}
            value={monthRange}
            lang={moment.months()}
            theme="light"
            onChange={handleMultiChange}
            onDismiss={handleClickMultiBox}
        />
    </div>;
};


const LockOrUnlockMonthButton = ({ availableDates, setAvailableDates, daysWithBookings }) => {
    const [isUnlock, setIsUnlock] = useState(true);

    const getCurrentMonthDays = () => {
        let selectedMonthNumber = document.querySelector('.i_day-picker-body .i_day-picker-row:nth-child(2) div')?.getAttribute('data-date')?.split('-')[0];
        let selectedyear = document.querySelector('.i_day-picker-title')?.textContent.replace(/\D/g, '');
        return getMonthDays(selectedyear, selectedMonthNumber, false);
    };

    const setLockOrUnlock = () => {
        setTimeout(() => {
            let currentMonthDays = getCurrentMonthDays();
            if (currentMonthDays.length > 0) {
                let currentMonthNumber = currentMonthDays[0].month();
                let currentYear = currentMonthDays[0].year();
                let currentMonthAvailableDays = availableDates
                    .filter(d => d.month() === currentMonthNumber && d.year() === currentYear)
                    .filter(d => !daysWithBookings.includes(d.format('YYYY-MM-DD')));
                let currentMonthLockedDays = currentMonthDays.length - currentMonthAvailableDays.length;
                (currentMonthLockedDays > currentMonthAvailableDays.length) ? setIsUnlock(true) : setIsUnlock(false);
            }
        }, 100);
    };

    const unlockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        setAvailableDates(ad => [...ad, ...currentMonthDays]);
    };

    const lockCurrentMonth = () => {
        let currentMonthDays = getCurrentMonthDays();
        console.log({ currentMonthDays });
        let lockableDays = currentMonthDays.filter(d => !daysWithBookings.includes(d.format('YYYY-MM-DD')));

        // substract the current month days to the availableDates selected
        let newAvailableDates = (() => {
            let nad = [];
            availableDates.forEach(ad => {
                if (lockableDays.filter(cmd => cmd.format('DD-MM-YYYY') === ad.format('DD-MM-YYYY')).length === 0) nad.push(ad);
            });
            return nad;
        })();

        setAvailableDates(newAvailableDates);
    };

    const toggleMonthAvailability = () => {
        isUnlock ? unlockCurrentMonth() : lockCurrentMonth();
        setIsUnlock(iu => !iu);
    };

    useEffect(() => {
        setLockOrUnlock();

        let monthNavigationButtons = document.getElementsByClassName("e_day-picker-arrow-container");
        for (var i = 0; i < monthNavigationButtons.length; i++)
            monthNavigationButtons[i].addEventListener('click', setLockOrUnlock);

        return () => {
            for (var i = 0; i < monthNavigationButtons.length; i++)
                monthNavigationButtons[i].removeEventListener('click', setLockOrUnlock);
        };

        // eslint-disable-next-line
    }, [availableDates]);

    return <Button onClick={toggleMonthAvailability} style={{ color: '#00D1BF', fontFamily: 'Poppins', textTransform: 'none' }}>{isUnlock ? 'Desbloquear mes' : 'Bloquear mes'}</Button>;
};


const LockOrUnlockYearButton = ({ availableDates, setAvailableDates, daysWithBookings }) => {
    const [isUnlock, setIsUnlock] = useState(true);

    const getCurrentYearDays = () => {
        let currentYear = document.querySelector('.monthCalendarContainerSpace div.rmp-cell > div > div > div > label')?.textContent;
        const currentYearDays = [];
        for (let i = 1; i <= 12; i++) {
            currentYearDays.push(...getMonthDays(+currentYear, i));
        }
        return currentYearDays;
    };

    const setLockOrUnlock = () => {
        setTimeout(() => {
            let currentYearDays = getCurrentYearDays();
            if (currentYearDays.length > 0) {
                let currentYear = currentYearDays[0].year();
                let currentYearAvailableDays = availableDates
                    .filter(d => d.year() === currentYear);
                //.filter(d => !daysWithBookings.includes(d.format('YYYY-MM-DD')));
                let currentMonthLockedDays = currentYearDays.length - currentYearAvailableDays.length;
                (currentMonthLockedDays > currentYearAvailableDays.length) ? setIsUnlock(true) : setIsUnlock(false);
            }
        }, 300);
    };

    const unlockCurrentYear = () => {
        let monthButtons = document.querySelectorAll(".monthCalendarContainerSpace .rmp-btn.multiple:not(.active)");
        monthButtons.forEach(mb => {
            setTimeout(() => mb.click(), 50);
        });
    };

    const lockCurrentYear = () => {
        let monthButtons = document.querySelectorAll(".monthCalendarContainerSpace .rmp-btn.multiple.active");
        monthButtons.forEach(mb => {
            setTimeout(() => mb.click(), 50);
        });
    };

    const toggleMonthAvailability = () => {
        isUnlock ? unlockCurrentYear() : lockCurrentYear();
        setIsUnlock(iu => !iu);
    };


    useEffect(() => {
        setLockOrUnlock();

        let yearNavigationButtons = document.querySelectorAll(".monthCalendarContainerSpace i.rmp-tab.rmp-btn");
        for (var i = 0; i < yearNavigationButtons.length; i++)
            yearNavigationButtons[i].addEventListener('click', setLockOrUnlock);

        return () => {
            for (var i = 0; i < yearNavigationButtons.length; i++)
                yearNavigationButtons[i].removeEventListener('click', setLockOrUnlock);
        };

        // eslint-disable-next-line
    }, [availableDates]);

    return <Button onClick={toggleMonthAvailability} style={{ color: '#00D1BF', fontFamily: 'Poppins', textTransform: 'none' }}>{isUnlock ? 'Desbloquear año' : 'Bloquear año'}</Button>;
};




const PositionDay = props => {

    const { daysWithBookings, date, isSelected, isInThePast, label } = props;
    const currentDate = date?.moment?.format('YYYY-MM-DD');

    const onClick = (e) => {
        if ((isSelected && daysWithBookings?.includes(currentDate)) || isInThePast) e.stopPropagation();
    }

    return (<Tooltip title={(daysWithBookings?.includes(currentDate) && !isInThePast) ? 'Fecha con bookings' : ''} enterTouchDelay={0}>
        <div className={(isSelected || !isFromSelectedMonth(date)) ? '' : 'diagonal-container'} id="availabilityEditCalendar" onClick={onClick}>
            <div className={getStyle(props)}
                style={{ ...getInline(props), display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Badge color="secondary" variant="dot" invisible={(isInThePast || !daysWithBookings?.includes(currentDate) || !isFromSelectedMonth(date))}>
                    <div style={{ fontSize: '14px', color: !isFromSelectedMonth(date) && '#383839', opacity: !isFromSelectedMonth(date) && '0.5', }}>{label}</div>
                </Badge>
            </div>
        </div>
    </Tooltip>);
};

const getStyle = function ({ date, isSelected }) {
    return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`;
};

const getInline = ({ isToday, isInThePast }) => ({
    cursor: isInThePast ? 'not-allowed' : 'inherit',
    background: isInThePast ? '#e4e4e4' : 'inherit',
    color: isInThePast ? '#555555' : 'inherit',
});

const isFromSelectedMonth = date => (date.type === 'month');

const pad = d => (d < 10) ? '0' + d.toString() : d.toString();

const getMoment = (time) => {
    let [h, m] = time.split(':');
    return moment({ h, m });
};

const calcHourDiff = (checkin, checkout) => getMoment(checkout).diff(getMoment(checkin), 'hours');

export const sortMonthRange = (a, b) => {
    return a.year - b.year || a.month - b.month;
};


export const getMonthDays = (selectedyear, selectedMonthNumber1, useUTC = true) => {
    const selectedMonthNumber = pad(+selectedMonthNumber1);
    let daysInMonth = moment(`${selectedyear}-${selectedMonthNumber}-01`).daysInMonth();
    let arrDays = [];
    let currentTz = useUTC ? 'UTC' : momentTimezone.tz.guess();
    while (daysInMonth) {
        var current = momentTimezone.tz(`${selectedyear}-${selectedMonthNumber}-${pad(daysInMonth)} 00:00`, currentTz);
        const isTodayOrAfter = (current.isAfter(moment()) || current.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'));
        const isWeekDay = (current.isoWeekday() !== 6 && current.isoWeekday() !== 7);
        if (isTodayOrAfter && isWeekDay) arrDays.push(current);
        daysInMonth--;
    }
    return arrDays;

};
