// External components
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Slide,
    TextField,
    Typography,
    createStyles,
    makeStyles,
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';

// Utils & Config
import is from 'is_js';

import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { companyAdminRolesDictionary } from '../../helpers/dictionaries/companyAdminRolesDictionary';
import { CompanyAdminRoleEnum } from '../../helpers/enums/CompanyAdminRoleEnum';
import { getEmployeeDisplayName } from '../../helpers/funcs/getEmployeeDisplayName';
import FeaturesService from '../../services/FeaturesService';
import AdminService from '../../services/AdminService';

const CreateOrUpdateCompanyAdminsModal = ({ open, onClose, onConfirm, mode, admin, company }) => {
    const theme = useTheme();

    const createModeFormValues = { email: '', roles: [] };
    const updateModeFormValues = { email: admin?.email ?? admin?.user.email, roles: admin?.roles };

    const initialFormValues = mode === 'CREATE' ? createModeFormValues : updateModeFormValues;

    const [formValues, setFormValues] = useState([initialFormValues]);
    const [createAdminsReq, setCreateAdminsReq] = useState('IDLE');
    const [errors, setErrors] = useState({});

    const handleFormValues = (i, key, value) => {
        const formValuesDraft = [...formValues];
        formValuesDraft[i][key] = value;
        setFormValues(formValuesDraft);
    };

    const handleAddAdmin = () => {
        const newAdminsQuantity = formValues.length + 1;

        if (newAdminsQuantity > company?.max_admins) {
            return;
        }

        setFormValues([...formValues,  { email: '', roles: [] }]);
    };

    const handleRemoveAdmin = (i) => {
        const formValuesDraft = [...formValues];
        formValuesDraft.splice(i, 1);
        setFormValues(formValuesDraft);
    };

    const checkErrors = () => {
        const errs = {};

        for (let i = 0; i < formValues.length; i++) {
            const admin = formValues[i];

            if (!is.email(admin.email)) errs[i] = { ...errs[i], invalidEmail: true };
            if (!admin.roles?.length) errs[i] = { ...errs[i], emptyRoles: true };
        }

        return errs;
    };

    const createAdmins = async () => {
        setErrors({});

        const errs = checkErrors();

        if (Object.keys(errs).length > 0) {
            setErrors(errs);
            return;
        }

        setCreateAdminsReq('LOADING');

        try {
            await AdminService.createCompanyAdmins(company?.id, { admins: Object.values(formValues) });
            setCreateAdminsReq('SUCCESS');
            await onConfirm();
        } catch (error) {
            setCreateAdminsReq('ERROR');
        }
    };

    const updateCompanyAdminRoles = async () => {
        setErrors({});

        const errs = checkErrors();

        if (Object.keys(errs).length > 0) {
            setErrors(errs);
            return;
        }

        setCreateAdminsReq('LOADING');

        try {
            await FeaturesService.updateCompanyAdminRoles(company?.id, admin?.id, { roles: formValues[0].roles });
            setCreateAdminsReq('SUCCESS');
            await onConfirm();
        } catch (error) {
            setCreateAdminsReq('ERROR');
        }
    };

    const { _content, _actions } = useStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth TransitionComponent={Transition} disableEnforceFocus>
            <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant={'h5'}>
                    {mode === 'CREATE' && 'Añadir un nuevo administrador'}
                </Typography>

                {mode === 'UPDATE' && (
                    <Box style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                        <Avatar src={admin?.user.avatar?.url} style={{ height: theme.spacing(2.5), width: theme.spacing(2.5) }}>
                            {admin?.user?.name
                                ? `${admin?.user.name[0]}${admin?.user.lastname && admin?.user.lastname[0]}`
                                : null
                            }
                        </Avatar>

                        <Typography variant={'h5'}>
                            Editar administrador - {getEmployeeDisplayName(admin)}
                        </Typography>
                    </Box>
                )}
            </DialogTitle>

            <DialogContent className={_content}>
                {mode === 'CREATE' && (
                    <React.Fragment>
                        <Typography gutterBottom>
                            Ingrese los correos electrónicos de los nuevos administradores de la empresa
                        </Typography>
                    </React.Fragment>
                )}

                {formValues.map((formValue, i) => (
                    <Box key={i} style={{ display: 'flex', justifyContent: 'space-between', gap: theme.spacing(2) }}>
                        {mode === 'CREATE' && (
                            <Box>
                                <IconButton disabled={i === 0} onClick={() => handleRemoveAdmin(i)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        )}

                        <TextField
                            label={'Email'}
                            variant={'outlined'}
                            name={'email'}
                            onChange={(e) => handleFormValues(i, 'email', e.target.value)}
                            style={{ width: '50%' }}
                            value={formValue.email}
                            disabled={mode === 'UPDATE'}
                            error={errors[i]?.invalidEmail}
                            helperText={errors[i]?.invalidEmail && 'Email inválido'}
                        />

                        <FormControl
                            variant={'outlined'}
                            style={{ width: '50%' }}
                        >
                            <InputLabel
                                id={'admin-role-label'}
                                error={errors[i]?.emptyRoles}
                            >
                              Roles
                            </InputLabel>

                            <Select
                                label={'Roles'}
                                value={formValue.roles}
                                name={'roles'}
                                onChange={(e) => handleFormValues(i, 'roles', e.target.value)}
                                displayEmpty
                                multiple
                                error={errors[i]?.emptyRoles}
                                renderValue={(selectedRoles) => (
                                    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {selectedRoles?.map((value) => (
                                            <Chip
                                                key={'role_sel_' + value + i}
                                                label={companyAdminRolesDictionary[value]}
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {Object.keys(CompanyAdminRoleEnum).map((role) => (
                                    <MenuItem key={role} value={role}>
                                        <Checkbox checked={(formValues[i].roles).includes(role)} />
                                        <ListItemText primary={companyAdminRolesDictionary[role]} />
                                    </MenuItem>
                                ))}
                            </Select>

                            <FormHelperText error>{errors[i]?.emptyRoles && 'Por favor selecciona al menos un rol'}</FormHelperText>
                        </FormControl>
                    </Box>
                ))}

                {mode === 'CREATE' && (
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleAddAdmin}
                            disabled={formValues.length >= company?.max_admins}
                            startIcon={<AddIcon />}
                        >
                            Añadir otro
                        </Button>
                    </Box>
                )}

            </DialogContent>

            <DialogActions className={_actions}>
                <Box>
                    {createAdminsReq === 'LOADING' && (
                        <CircularProgress size={24}  />
                    )}

                    {createAdminsReq === 'ERROR' && (
                        <Typography color={'error'}>
                            Algo salió mal, intente de nuevo
                        </Typography>
                    )}
                </Box>

                <Box>
                    <Button
                        color={'primary'}
                        onClick={onClose}
                        disabled={createAdminsReq === 'LOADING'}
                    >
                        Cerrar
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={mode === 'CREATE' ? createAdmins : updateCompanyAdminRoles}
                        disabled={createAdminsReq === 'LOADING'}
                    >
                        Guardar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrUpdateCompanyAdminsModal;

const Transition = function Transition(props) {
    return <Slide direction={'left'} {...props} />;
};

const useStyles = makeStyles((theme) => createStyles({
    _content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    _actions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
}));
