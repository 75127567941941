export const FeatureCategoryNameEnum = {
    COWORKINGS: 'COWORKINGS',
    STORE: 'STORE',
    FOOD: 'FOOD',
    MOBILITY: 'MOBILITY',
    PROFESSIONAL_DEVELOPMENT: 'PROFESSIONAL_DEVELOPMENT',
    WELLNESS: 'WELLNESS',
    SOCIAL: 'SOCIAL',
    ANALYTICS: 'ANALYTICS',
};
